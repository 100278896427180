.addnew1 {
  width: 100%;
}

.addnew1-container {
  width: 100%;
  margin-top: 5%;
}

.addnew1-title {
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: center;
  font-size: 15px;

  >span {
    font-weight: 700;
  }

  >span:nth-child(2) {
    padding-left: 1%;
  }

  >span:nth-child(3) {
    padding-left: 1%;
  }

  >span:nth-child(4) {
    padding-left: 0.5%;
  }
}

.addnew1-page {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #f5f5f5;
}

.addnew1-page1 {
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  // border-bottom: 3px solid #CB1B5B;
  color: #cb1b5b;

  >div:nth-child(1) {
    width: 34.5px;
    height: 32.5px;

    >img {
      width: 100%;
      height: 100%;
    }
  }

  >div:nth-child(2) {
    width: 60%;
    height: 65%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    font-weight: 500;
    font-size: 15px;
  }
}

.addnew2-page1 {
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;

  //  border-bottom: 3px solid #CB1B5B;
  >div:nth-child(1) {
    width: 34.5px;
    height: 32.5px;

    >img {
      width: 100%;
      height: 100%;
    }
  }

  >div:nth-child(2) {
    width: 60%;
    height: 65%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    font-weight: 500;
    font-size: 15px;
  }
}

.col-12 {
  margin-bottom: 15px;
}

.addnew2-page2 {
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #cb1b5b;
  color: #cb1b5b;
  margin-left: 5%;

  >div:nth-child(1) {
    width: 34.5px;
    height: 32.5px;

    >img {
      width: 100%;
      height: 100%;
    }
  }

  >div:nth-child(2) {
    width: 60%;
    height: 65%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    font-weight: 500;
    font-size: 15px;
  }
}

.addnew1-page2 {
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 5%;

  >div:nth-child(1) {
    width: 35px;
    height: 33px;

    >img {
      width: 100%;
      height: 100%;
    }
  }

  >div:nth-child(2) {
    width: 60%;
    height: 65%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    font-weight: 500;
    font-size: 15px;
  }
}

.addnew1-form {
  width: 100%;
  margin-top: 5%;
}

.addnew-form-upload {
  display: flex;
}

.star {
  color: #cb1b5b;
}

.addnew-form1-next {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;

  >div {
    // width: 17%;
    // height: 70%;
    width: 166px;
    height: 50px;
    display: flex;
    border-radius: 4px;
    background: #3b86ff;
    cursor: pointer;

    >div:nth-child(1) {
      width: 70%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 100%;
        height: 80%;
        background: #3b86ff;
        border: 1px solid transparent;
        color: #fff;
        padding-left: 40%;
        letter-spacing: 1px;
      }
    }

    >div:nth-child(2) {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: left;

      img {
        width: 35%;
      }
    }
  }
}

.formNextBtn {
  display: flex;
  justify-content: right;
  margin: 3rem 0;

  div {
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #3b86ff;
    cursor: pointer;

    button {
      background: #3b86ff;
      border: 1px solid transparent;
      color: #fff;
      letter-spacing: 1px;
    }

    img {
      width: 25px;
    }
  }
}

.nextBackBtns,
.backSubmitBtns {
  display: flex;
  justify-content: flex-end;
}

.backSubmitBtns {
  .nextBackBtns {
    width: 100%;
  }
}

.nextBtnStyle {
  // width: 17%;
  // height: 70%;
  width: 166px;
  height: 50px;
  display: flex;
  border-radius: 4px;
  background: #3b86ff;
  cursor: pointer;

  >div:nth-child(1) {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 100%;
      height: 80%;
      background: #3b86ff;
      border: 1px solid transparent;
      color: #fff;
      padding-left: 40%;
      letter-spacing: 1px;
    }
  }

  >div:nth-child(2) {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: left;

    img {
      width: 35%;
    }
  }
}

.addnew-input {
  height: 50px !important;
  color: #b2b1b6;
  font-size: 13px;
  // padding-left: 10%;
  width: 269px;
}
.healthsynergyFormInput, .grayColor{
  color: #b2b1b6;
}
.ogTagsField {
  height: 150px !important;
}

.applinks {
  max-width: 600px;
  width: 100%;
}

#title {
  width: 100%;
}

.addnew-form-menu {
  display: flex;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  outline: none;
  border: 1px solid gray;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: #cb1b5b;
}

input[type="radio"]:checked {
  border-color: #cb1b5b;
}

.form-check-label {
  padding-top: 20%;
  font-size: 15px;
  font-weight: 600;
}

.form-check {
  display: flex;
}

.addnew-form1-featured {
  margin-left: 5%;
}

.title {
  margin-bottom: 24px;
}

.addnew2-form {
  width: 100%;
  margin-top: 5%;
}

.addnew-add {
  background: #54a464;
  color: #fff;
  border: 1px solid #54a464;
  border-radius: 4px;
}

.addnew-back {
  background: #fff;
  color: #5697ff;
  border: 1px solid #5697ff;
  border-radius: 4px;
}

.addnew-form-active {
  width: 100%;
  height: 10vh;
  display: flex;
  margin-top: 3%;
}

.addnew-active {
  width: 25%;
  height: 100%;

  >div:nth-child(1) {
    width: 100%;
    height: 30%;
  }

  >div:nth-child(2) {
    width: 100%;
    height: 70%;
    display: flex;

    >div:nth-child(1) {
      width: 50%;
      height: 100%;
      display: flex;

      >div:nth-child(1) {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      >div:nth-child(2) {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5%;
        font-weight: 500;
      }
    }

    >div:nth-child(2) {
      width: 50%;
      height: 100%;
      display: flex;

      >div:nth-child(1) {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      >div:nth-child(2) {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5%;
        font-weight: 500;
      }
    }
  }
}

.addnew-featured {
  width: 25%;
  height: 100%;
  margin-left: 5%;

  >div:nth-child(1) {
    width: 100%;
    height: 30%;
  }

  >div:nth-child(2) {
    width: 100%;
    height: 70%;
    display: flex;

    >div:nth-child(1) {
      width: 50%;
      height: 100%;
      display: flex;

      >div:nth-child(1) {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      >div:nth-child(2) {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5%;
        font-weight: 500;
      }
    }

    >div:nth-child(2) {
      width: 50%;
      height: 100%;
      display: flex;

      >div:nth-child(1) {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      >div:nth-child(2) {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5%;
        font-weight: 500;
      }
    }
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "Select some files";
  display: inline-block;
  background: #cccccc 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000026;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;

  font-weight: 700;
  font-size: 10pt;
}

// input[type="file"]{
//     font-size: 13px;
//     border: 1px solid #D4D4D4;
//     border-radius: 5px;
//     width: 105px;
//     height: 34px;
//     background: #CCCCCC 0% 0% no-repeat padding-box;
//     box-shadow: 0px 2px 4px #00000026;
//     border-radius: 5px;

// }

textarea {
  border: 1px solid #e6e6e6;
}

input:active {
  outline: none;
}

textarea:active {
  outline: none;
}

.addnew1-form1-next {
  width: 100%;
  height: 10vh;
  margin-top: 10%;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.addnew1-back-next {
  width: 45%;
  height: 70%;
  display: flex;
}

.addnew1-back {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;

  div {
    // width: 80%;
    // height: 100%;
    width: 166px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #3b86ff;
    display: flex;
    cursor: pointer;

    >div:nth-child(1) {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      border: 1px solid transparent;

      img {
        width: 30%;
      }
    }

    >div:nth-child(2) {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 5%;
      color: #3b86ff;
      font-weight: 500;
      border: 1px solid transparent;

      >button {
        border: 1px solid transparent;
      }
    }
  }
}

.addnew1-next {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;

  div {
    // width: 80%;
    // height: 100%;
    width: 166px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #54a464;
    background: #54a464;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.back-button {
  width: 20%;
  position: fixed;
  top: 5%;
  z-index: 999;
  background: #fff;
}

.rose-back {
  color: #cb1b5b;
  padding-left: 5%;
}

.rose {
  cursor: pointer;
}

.jodit-status-bar a.jodit-status-bar-link {
  display: none;
}

.jodit-workplace+.jodit-status-bar:not(:empty) {
  display: none;
}

//input file-upload css
.custom-file-input {
  color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "Choose Files";
  color: black;
  display: inline-block;
  background: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 10px 25px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.fo-tit {
  margin-bottom: 5%;
}

.fo-tit-top {
  margin-top: 5%;
}

label {
  font-size: 15px;
}

.canonical {
  margin-bottom: 1rem;
}

.healthArticleView {
  .form-label {
    color: #4b4853 !important;
    font-family: 13px;
  }

  .form-control {
    //  background: #f8f8f8 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    // border:1px solid  #D4D4D4  !important;
  }
}

//upload button
#uploadImage {
  width: 120px;
}

#authorImage {
  width: 120px;
}

.authorUpload {
  margin-top: -0.5%;
  margin-left: 10%;
}

.health-art-form {
  width: 97%;
}

.menu-input,
.category-in {
  background-color: #f5f5f5 !important;
}

input[type="radio"]:checked:before {
  background: #fff;
}

input[type="radio"]:checked {
  border-color: #cb1b5b;
  background-color: #cb1b5b;
}

//view portion
.blog-view-title {
  margin-top: 5%;
}

.blog-view-text-half {
  // width: 269px;
  height: 50px;
  background: #f8f8f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10%;
}

.blog-view-text-half-bor {
  // width: 269px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10%;
  border: 1px solid #d4d4d4;
}

.blog-view-text-full {
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 2%;
  border: 1px solid #d4d4d4;
}

.blog-view-text-full-des {
  // height: 101px;
  height: 200px;
  border-radius: 5px;
  // display: flex;
  // justify-content: left;
  padding-left: 2%;
  padding-top: 2%;
  overflow: scroll;
  border: 1px solid #d4d4d4;
}

.blog-view-text-full-des::-webkit-scrollbar {
  display: none;
}

.metatags-view {
  overflow-x: scroll;
  padding-left: 20px;
}

.metatags-view::-webkit-scrollbar {
  display: none;
}

.v-dynamic-tags {
  padding: 2px 10px;
  border-radius: 4px;
  margin-left: 20px;
  background-color: #e8f1ff;
  border: 1px solid #e8f1ff;
  font-weight: bold;
}

.v-dynamic-tags:first-child {
  margin-left: 0px;
}

#img-preview-one,
#img-preview-two {
  display: none;
  width: 10%;
  // border: 2px dashed #333;
  margin-bottom: 20px;
}

#img-preview-one img,
#img-preview-two img {
  width: 200px;
  height: auto;
  display: block;
}
.ml-7{margin-left: 7%;}
.width-76{width: 76%;}
.doctor-page-tick {
    width: 97%;
    margin: auto;
    height: 10vh;
    margin-top: 2%;
    display: flex;
    border-bottom: 1px solid rgb(242, 239, 239);

    >div:nth-child(1) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 3px solid #6eb17b;

        >img {
            width: 24px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(2) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;

        >img {
            width: 30px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
        }
    }
}

.doctor-page2-tick {
    width: 97%;
    margin: auto;
    height: 10vh;
    margin-top: 2%;
    display: flex;
    border-bottom: 1px solid rgb(242, 239, 239);

    >div:nth-child(1) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;

        >img {
            width: 24px;
            height: 27px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }

    >div:nth-child(2) {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 5%;
        border-bottom: 3px solid #6eb17b;

        >img {
            width: 24.5px;
            height: 26px;
        }

        >div {
            padding-left: 5%;
            font-size: 13px;
            color: #6eb17b;
        }
    }
}


.doctor-textarea {
    width: 100%;
    height: 74px;
    background: transparent;
    color: #000;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
}

.doc-btn-add {
    button {
        background: transparent;
        border: 1px solid transparent;
        color: #fff;
    }
}

.medicineDetails-page-tick {
    background: #FFFFFF;
    margin: 16px 0 10px;
    padding: 10px 0 10px 10px;
    display: flex;
    flex-direction: row;
    gap: 78px;
}

.doctor-page-tick,
.doctor-page2-tick {
    display: flex;
    margin-bottom: 20px;
}

.active-step {
    color: #CB1B5B;
    border-bottom: 4px solid #CB1B5B;
    border-radius: 4px;
}

.inactive-step {
    color: #D2D2D2;
}

.inactive-step:hover {
    color: #D2D2D2;
    cursor: pointer;
}



.active-selected {
    background-color: #28a745;
    border-color: #28a745;
}

.active-unselected {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
}

.inactive-selected {
    background-color: #dc3545;
    border-color: #dc3545;
}

.inactive-unselected {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
}
.selectedBoxField {
  border: 2px solid #ff1493;
}

.medicineDetailsWrapper {
  .medicineDetailsdBtn {
    height: 50px;
    padding: 15px 44px;
    background: #FAFAFA;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #E9E9E9;
  }

}

.medicinesListWrapper {
  .medicineUploadFile {
    padding: 32px;
    border-radius: 8px;
    background: #FAFAFA;
  }

  .medicineFormats {
    color: #797979;
    padding: 10px 0 25px;
  }

  .medicineBrowseBtn {
    height: 48px;
    border: none;
    color: #CB1B5B;
    padding: 13px 35px;
    background: #FAE8EF;
  }

  .medicineImg {
    padding: 8px 0 24px;
  }

  .medicineUploadImgWrapper {
    padding: 43px 0 42px;
    border: 2px dashed #A6A6A6;
  }

}

.cover-label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: black;
  padding: 5px;
  font-size: 14px;
}

.customSelectField {
  height: 50px !important;
}

.customFields {
  background: #FAFAFA;
  border-radius: 8px;
  border: 1px solid #E9E9E9;
}

:where(.css-dev-only-do-not-override-161f05s).ant-select .ant-select-arrow {
  top: 50% !important;
}

// .medicineManufacturerDetails {
//   border-radius: 8px;
//   border: 1px solid #E9E9E9;
//   padding: 19px 20px 19px 24px;
// }

.medicineAddVariants {
  // width: 14%;
  padding: 10px;
  color: #fff;
  border-radius: 8px;
  background: #CB1B5B;
  border: none;
}

// .AddNewVariants {
//   width: 100%;
//   background: none;
//   color: #fff;
//   border: none;
// }

.medicineDeleteBtn {
  background: #EB0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 1px 8px;

  img {
    cursor: pointer;
  }
}

.medicineDeleteImg {
  width: 20px;
  height: 20px;
}

.deletePopup {
  padding: 32px 0 0;
}

.cancelBtn {
  background: #fff;
  border-radius: 8px;
  padding: 12px 37px;
  border: 1px solid #4D4D4D;
}

.deleteBtn {
  color: #fff;
  border: none;
  background: #EB0000;
}

.medicineRootModal {
  height: auto;
  width: 308px;
  position: fixed;
  left: 40%;
  top: 25%;
  box-shadow: 0px 1px 10px rgba(71, 71, 71, 0.1607843137);
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  transition: 2s;
  padding: 24px;
  z-index: 5;
}

.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-title:only-child {
  font-size: 16px;
  font-weight: 600;
}

.ant-btn-sm {
  font-size: 14px;
  height: 42px;
  padding: 12px 37px;
  border-radius: 4px;
}

.ant-popconfirm .ant-popconfirm-buttons {
  padding: 32px 0 0;
}

.anticon svg {
  display: none !important;
}

.ant-popover {
  left: 46% !important;
}

.ant-popover .ant-popover-inner {
  padding: 24px !important;
}

.ant-upload,
.ant-upload-select {
  border-color: none !important;
  border: none !important;
}

.ant-popover .ant-popover-content {
  width: 100%;
  max-width: 308px;
  text-align: center;
}

.medicineFaqclr {
  background: #CB1B5B;
}

.medicineAddFAQ {
  width: fit-content;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
}

.faqDeleteImg {
  width: 50px;
  height: 30px;
}

.medicineFaqs {
  width: 100%;
}

.faqDeleteImgWrapper {
  background: red;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  padding: 11px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-upload-item {
  position: relative;
  display: inline-block;
}

.cover-image {
  display: block;
  width: 200px;
  border-radius: 8px;
}

.cover-label {
  width: 100%;
  max-width: 78px;
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: #CB1B5B;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  z-index: 10;
  padding: 5px 15px;
  border-top-left-radius: 22px;
  border-bottom-right-radius: 28px;
}

.orderMedicineCoverImg {
  z-index: 999;
  position: relative !important;
  background: #F3F3F3;
  width: 115px;
  height: auto;
  padding: 10px;
  border-radius: 10px;
}

.ant-upload-list,
.ant-upload-list-picture-card {
  gap: 60px !important;
}

.uploadContent {
  .ant-upload-wrapper {
    position: relative;
  }

  .ant-upload-list-item {
    bottom: -27px;
  }

  // .ant-upload-list-item-container {
  // position: relative;
  // z-index: 9999;
  // }
  // .ant-upload-list-item{
  //   height: auto !important;
  // }
  // position: relative;
  .ant-upload-list-text {
    position: absolute;
    max-width: 182px;
    top: 0;
    // bottom: 0;
  }
}

.deleteOption {
  background: red;
  border-radius: 50%;
  width: 33px;
  padding: 4px;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.ant-upload-list-item,
.ant-upload-list-item-error {
  padding: 0 !important;
}

.ant-upload-list-item,
.ant-upload-list-item-error,
.ant-upload-list-item-done,
.ant-tooltip-open,
.ant-upload-list-picture-card,
.ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
  background: none !important;
  padding: 0 !important;
}

.ant-upload.ant-upload-select {
  width: 100% !important;
  height: auto !important;

  .medicineUploadImgWrapper {
    width: 100%;
  }
}

.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  // justify-content: space-between;
  .orderMedicineCoverImg {
    flex: 1 1 calc(20% - 10px);
    /* 20% width considering gap */
    max-width: 20%;
    min-width: 100px;

    /* Ensures responsiveness */
    img {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }
  }
}

.radioBtnWrapper {
  background: #FAFAFA !important;
  padding: 15px 30px !important;
  border-radius: 8px !important;
  border: 1px solid #E9E9E9 !important;

  span {
    padding: 0 !important;
    color: #181423 !important;
  }

  .ant-radio-wrapper .ant-radio {
    visibility: hidden !important;
  }
}

.inputRadioBtn {
  background: #FAE8EF !important;
  border: 1px solid #E0769D !important;

  span {
    color: #CB1B5B !important;
  }
}

.medicineFormWrapper {

  .ant-collapse,
  .ant-collapse-content {
    margin-top: 24px;
    border: none;
  }

  .ant-collapse-content {
    margin-top: 0;
  }

  .css-hlgwow {
    height: 50px;
  }
}

.faqMedicineInputWrapper {
  width: -webkit-fill-available;
}

.antdBtnWrapper {
  .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {
    color: #CB1B5B !important;
    background: #f7dce0 !important;
  }

  .ant-btn {
    height: 50px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    // padding: 13px 30px !important;
    border: none !important;
    color: #CB1B5B !important;
    background: #f7dce0 !important;
  }
}

.medicineFilterModal {

  .ant-modal-content,
  .ant-modal-header {
    background: #fff;
  }

  .ant-col.ant-col-6,
  .ant-col.ant-col-10 {
    border-right: 1px solid rgba(0, 0, 10, 0.11);
    padding-bottom: 20px;
  }

  .ant-list-items {
    li {
      padding-left: 16px;
      border-radius: 8px;
      border: none;
    }

    .seletedItem {
      background: #F5F5F5;
    }
  }
}
// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;100&display=swap');
@import 'react-range-slider-input/dist/style.css';

.App {
  margin: 0%;
  padding: 0%;
}

body {
  background: #fff;
}

input[type="text"] {
  height: 50px;
}

input[type="number"] {
  height: 50px;
}

label {
  font-size: default !important;
  font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: brightness(0) saturate(100%) invert(19%) sepia(57%) saturate(3432%) hue-rotate(319deg) brightness(100%) contrast(101%);
}

// .clinicProfileTable{
//   width: max-content !important;
// }
.formEditIcon,
.formCloseIcon {
  width: 58px;
  height: 58px;
}

.formEditIcon {
  color: #2282ba;
}

.formCloseIcon {
  color: #df2e3f;
}

.ant-table-thead{
  .ant-table-cell{
    font-size: 18px;
    font-weight: 700 !important;
  }
}
.ant-table-tbody{
  color: #4D4D4D;
}
.ant-table-tbody td {
  font-size: 16px;
  font-weight: 500;
}

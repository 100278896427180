@import "./styles/mixins.scss";
@import "./styles/common.scss";
@import "./styles/sass/medicine.scss";
// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

.blogTitleSelect {
    .css-166bipr-Input {
        input {
            opacity: 1 !important;
        }
    }

    .css-1cfo1cf {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }
}

.primaryColor {
    color: #CB1B5B;
}

.primaryBackground {
    background-color: #CB1B5B;
}

.common-nav-tab {
    width: none !important;
}

.cursorPointer {
    cursor: pointer;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

.healtharticle-container {
    overflow: hidden;
}

.customNavRightArrow {
    position: absolute;
    right: 61px;
}

.customNavRightArrow {
    top: 16px;
    right: 52px;
    border: none;
    position: absolute;
    background-color: #fff;
}

.customNavtabTitle {
    font-size: 18px;
    font-weight: 500;
}

.navScrollContainer {
    padding-left: 19% !important;
}

td {
    word-wrap: break-word;
}

.orderMedicineOnline {
    width: 59px !important;
    height: 35px !important;
    justify-content: left !important;
}

.medicineManufacturer {
    background: #CB1B5B !important;
}

.addBtnWrapper {
    width: 100%;
    max-width: 188px;
    padding: 10px;
    gap: 10px;
    font-size: 13px;
}

.addBtn {
    border-radius: 8px !important;
}

.healthsyServices {

    .healthsyMasterBreadCrumbs,
    a {
        font-size: 24px !important;
        font-weight: 700 !important;
    }
}

.healthsyServiceActive {
    background: #E8F7EE;
    width: 100%;
    color: #158844;
    max-width: 77px;
    padding: 4px 8px;
    display: flex;
    gap: 6px;
    align-items: center;
    inline-size: block;
    border-radius: 8px;
    text-align: center;
}

.healthsyServiceInActive {
    width: 100%;
    max-width: 97px !important;
    background: #FCF5DC;
    display: flex;
    color: #C1A53F;
    border-radius: 8px;
    gap: 6px;
    text-align: center;
    padding: 4px 8px;
    align-items: center;
}

.healthsyServiceActiveDot {
    height: 8px;
    width: 8px;
    background-color: #158844;
    border-radius: 50%;
}

.healthsyServiceInActiveDot {
    height: 8px;
    width: 8px;
    background-color: #C1A53F;
    border-radius: 50%;
}

.healthsyServiceActvieStatus {
    gap: 32px;
}

.healthsyServiceInActvieStatus {
    gap: 12px;
}

.healthsyServicePopup {
    width: 100%;
    max-width: 145px;
    padding: 10px;
    background: #fff;
    z-index: 999;
    left: 42%;
    top: 55%;
    border-radius: 10px;
    border: 1px solid #E9E9E9;
}

.healthsyStoriesColor {
    color: #4E91C2;
    background: #EFF8FE;
    border: 1px solid #EFF8FE;
}

.healthsyStoriesBackgroundColor {
    color: #EB0000;
    border: 1px solid #FDE6E6;
    background: #FDE6E6;
}

.healthsyStoriesActionColor {
    color: #C1A53F;
    background: #FCF5DC;
    border: 1px solid #E8F7EE;
}

.healthsyServiceBtn {
    width: 100%;
    max-width: 120px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: start;
    margin: 0 0 6px;
    border: 1px solid #fff;
}

.healthsyServiceBtn:last-child {
    margin: 0;
}

.healthsyContainer {
    .healtharticle-title {
        width: 96% !important;
    }
}

.healthsyServicePopupModal {
    width: 100%;
    max-width: 130px;
    left: 23%;
    top: 52px;
    z-index: 9999;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #E9E9E9;
}

.manufacturerListFormWrapper {

    .manufacturerRadioBtn {
        padding: 15px 10px;
        border-radius: 8px;
        background: #FAFAFA !important;
        border: 1px solid #E9E9E9 !important;
    }
}

.saltMoleculesAlphabetsWrapper {
    gap: 15px;
    padding: 28px 26px 26px 31px;
    flex-wrap: wrap;
}

.saltMoleculeUncheck {
    color: #fff;
    background: #CB1B5B;
    border: 1px solid #fff;
}

.saltMoleculeLetters {
    width: 100%;
    max-width: 25px;
    height: 25px;
    font-size: 14px;
    font-weight: 700;
    padding: 2px 7px;
    text-align: center;
    border-radius: 4px;
}

.saltMoleculeAlphabets {
    color: #CB1B5B;
    background: #fff;
    border: 1px solid #E9E9E9;
}

.popupMenuWrapper {
    ul {
        background: #fff;
        border-radius: 10px;
        padding: 10px;
        border: 1px solid #E9E9E9;
    }
}

.exportDwnWrapper {
    .exportDwnContent {
        font-size: 18px;
        padding: 10px 0 23px;
    }
}

.filterModelOptions {
    z-index: 9999;
}